export const i18n = {
  en: {
    text: 'This is an static english text from the file ./src/constants/i18n.ts',
    newsletter: 'E-MAIL',
    metaNavPayment: 'PAYMENT & DELIVERY',
    metaNavPrivacy: 'PRIVACY AGREEMENT',
    metaNavImprint: 'IMPRINT',
    metaNavWithdrawal: 'WITHDRAWAL',
    metaNavContant: 'CONTACT',
  },
  de: {
    text: 'Das ist ein statischer deutscher text von der Datei ./src/constants/i18n.ts',
    newsletter: 'E-MAIL Adresse',
    metaNavPayment: 'VERSAND & BEZAHLUNG',
    metaNavPrivacy: 'DATENSCHUTZ',
    metaNavImprint: 'IMPRESSUM',
    metaNavWithdrawal: 'WIDERRUFSRECHT',
    metaNavContant: 'KONTAKT',
  },
}
