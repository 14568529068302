import React, { Children, ReactChild } from 'react'

interface VimProps {
  children: any
  locale: string
  black?: boolean
  file?: string
}

const Vim = ({ locale, black, children }: VimProps): JSX.Element => {
  return (
    <div className="container-normal z-20">
      <div className={`p-3 ${black ? 'text-white bg-black' : ''} font-mono rounded-lg`}>
        <div className="flex flex-row">
          <div className="w-4 h-4 bg-red-600 mr-2 rounded-full" />
          <div className="w-4 h-4 bg-gray-600 mr-2 rounded-full" />
          <div className="w-4 h-4 bg-green-600 mr-2 rounded-full" />
        </div>
        <div className="terminal mt-5">
          {children}
          <p>~</p>
          <p>~</p>
          <p>~</p>
          <p>~</p>
          <p>~</p>
          <p>~</p>
          <p>~</p>
          <p>~</p>
          “info.txt” 7L, 229C
        </div>
      </div>
    </div>
  )
}

const VivNew = ({ locale, black, children, file = 'info.txt' }: VimProps): JSX.Element => {
  return (
    <div className="container-normal z-9">
      <div className="font-mono rounded-lg">
        <div className="border-black border-solid border-2 max-w-full bg-white rounded">
          <div className="pl-1 py-4 border-b-2 border-black flex">
            <div className="ml-4 bg-black w-3 h-3 rounded-full" />
            <div className="ml-2 bg-black w-3 h-3 rounded-full" />
            <div className="ml-2 bg-black w-3 h-3 rounded-full" />
          </div>
          <div className="max-w-full p-4">
            {children}
            <p>~</p>
            <p>~</p>
            <p>~</p>
            <p>~</p>
            <p>~</p>
            <p>~</p>
            <p>~</p>
            <p>~</p>“{file}” 22L, 33C
          </div>
        </div>
      </div>
    </div>
  )
}

export default VivNew
